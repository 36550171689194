/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-lock-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7 7a1 1 0 012 0v1H7zM6 9.3c0-.042.02-.107.105-.175A.64.64 0 016.5 9h3a.64.64 0 01.395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.64.64 0 019.5 12h-3a.64.64 0 01-.395-.125C6.02 11.807 6 11.742 6 11.7z"/><path pid="1" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M10 7v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V9.3c0-.627.46-1.058 1-1.224V7a2 2 0 114 0"/>',
    },
});
